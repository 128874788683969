@import "./layout/fonts.inc.css";
@import "./layout/variables.inc.css";
@import "cookieconsent/build/cookieconsent.min.css";

html, body {
    font-family: "Hanuman", Sans-Serif !important;
}

.container {
    @media (--media-xxl) {
        max-width: var(--viewport-xxl);
    }
}

.row {
    flex-grow: 1;
}

.input-group .btn {
    line-height: 1;
}

@media (--media-xxl) {
    .col-xxl-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-xxl-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-xxl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xxl-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-xxl-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-xxl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xxl-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-xxl-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-xxl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-xxl-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-xxl-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-xxl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.btn-brand, .badge-brand {
    background-color: var(--brandColour);
    color: #fff;
    border-color: var(--brandColour);

    &:hover, &:hover {
        color: #fff;
        background-color: var(--brandColourDarker);
    }
}


.btn-group-toggle .btn-brand {
    &:not(.active) {
        background-color: #fff;
        color: var(--brandColourLighter);
    }

    &.active {
        background-color: var(--brandColour);
        color: #fff;
    }

    &:hover {
        background-color: var(--brandColourLighter);
        color: var(--brandColourDarker);
    }

    &.focus {
        box-shadow: 0 0 0.2rem 0.05rem var(--brandColourLighter);
    }
}

.border-brand {
    border-color: var(--brandColour);
}