<template lang="pug">
    b-container#header(fluid).px-0
        b-container.px-0
            b-navbar(toggleable='xl' type='dark')
                b-navbar-brand(:to='{name: "home"}') ePictureMounts
                b-navbar-toggle(target="nav-collapse")
                    template(#default)
                        span.material-icons menu
                b-collapse(id="nav-collapse" is-nav)
                    b-navbar-nav
                        span(v-for='link, idx in links' :key='idx')
                            b-nav-item(
                                v-if='typeof link.to === "string"'
                                :to='{"name": link.to}'
                            ) {{ link.text }}
                            b-nav-item-dropdown(
                                v-else
                                :text='link.text'
                            )
                                b-dropdown-item(
                                    v-for='subLink, subIdx in link.to' :key='`${idx}-${subIdx}`' 
                                    :to='{"name": subLink.to}'
                                ) {{ subLink.text }}
                    b-navbar-nav.ml-auto
                        //- b-nav-form.mr-3.vat-toggle
                        //-     b-form-checkbox(v-model='isShowingExVAT' switch) Ex VAT Prices
                        b-nav-item(:to='{name: "cart"}') 
                            span.material-icons.mr-1 shopping_cart 
                            | Cart (&pound;{{ cartTotal }})
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';
import { BContainer, BNavbar, BNavbarBrand, BNavbarToggle, BCollapse, BNavbarNav, BNavItem, BFormCheckbox, BNavForm, BNavItemDropdown, BDropdownItem } from 'bootstrap-vue';
import { getCart, setVatMode } from '../../../ts/modules/cart';

interface NavLink {
    text: string,
    to: string|NavLink[]
}

@Component({
    components: {
        BContainer, BNavbar, BNavbarBrand, BNavbarToggle, BCollapse, BNavbarNav, BNavItem, BFormCheckbox, BNavForm, BNavItemDropdown, BDropdownItem
    }
})
export default class Header extends Vue {
    links: NavLink[] = [
        { text: 'About', to: 'about' },
        { text: 'Buying Guide', to: 'buying-guide' },
        { text: "Custom Picture Mounts", to: 'custom-mounts' },
        { text: "Museum Picture Mounts", to: 'museum-mounts' }
    ]

    isShowingExVAT = false;

    cartTotal = '0.00';

    @Watch('isShowingExVAT')
    async onVATChange() {
        let res = await setVatMode(this.isShowingExVAT ? 'ex' : 'inc');
        if (res) {
            this.$bus.emit('vat-change');
        }
    }

    async refreshCart(total: string|null = null) {
        if (!total) {
            let cart = await getCart();
            this.cartTotal = cart.total;
        } else {
            this.cartTotal = total;
        }
    }

    created() {
        this.$bus.on('cart-change', this.refreshCart);
        this.$bus.on('vat-change', this.refreshCart);
        this.refreshCart();
    }
}
</script>

<style lang="postcss" scoped>
    .navbar-toggler {
        border: none;
    }

    .vat-toggle {
        color: rgba(255, 255, 255, 0.5);
    }
</style>