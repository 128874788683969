<template lang="pug">
    .d-flex.flex-column.min-vh-100
        app-header

        .flex-fill
            .pt-3
                router-view

        app-footer
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { BContainer, BNavbar, BCollapse, BNavbarNav, BNavItem, BNavbarToggle } from 'bootstrap-vue';
import VueBus from 'vue-bus';

import AppHeader from '@/vue/components/layout/Header.vue';
import AppFooter from '@/vue/components/layout/Footer.vue';

Vue.use(VueBus);

@Component({
    components: {
        AppHeader, AppFooter,
        BContainer, BNavbar, BCollapse, BNavbarNav, BNavItem, BNavbarToggle
    },
    metaInfo: {
        meta: [
            { name: 'robots', content: 'index, follow' }
        ]
    }
})
export default class App extends Vue {
}
</script>

<style lang="postcss">
    #header, #footer {
        background: var(--brandColour);
        color: white !important;
    }
</style>